.react-calendar{
    width:unset !important;
    border:unset !important;
}

@font-face {
    font-family: barcode1;
    /* src: url(../css/Lucida-Console-Regular.ttf); */
    src: url(../css/CONSOLAB.TTF);
}

.sample-barcode{
    font-family:barcode1;
}

@font-face {
    font-family: 'Rockwell';
    src: url('../css/ROCK.TTF') format('ttf');
  }

.search-group:focus{
    box-shadow: unset !important;
    border-color: #ced4da !important;
}

.list-group-item{
    cursor: pointer;
}

.list-group-item:hover{
    background-color: blanchedalmond;
}

.height-full{
    height: 100vh;
    overflow-y: auto;
}

.nav-active{
    color: white !important;
    background: #1f94b9 !important;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
}

.h-100{
    height: 100vh !important;
    overflow-y: auto;
}

.print-hr{
    border-bottom: 2px solid;
    border-top: 0px;
    width:'100%';
    font-weight:'bold';
    margin-bottom: 2.5rem;
}

.print-font{
    /* font-family: 'Times New Roman', Times, serif; */
    font-family: "Poppins";
    color: black !important;
    /* background-color: white !important; */
    font-size: 18px !important;
    padding: 2px !important;
}

.schedule .btn-primary:focus{
    background-color: #06c9b9;
    border-color: transparent;
}

.padding-1-table tr td {
    padding: 1px !important;
    text-align: left !important;
}

.no-padding-table tr td {
    padding: 1px !important;
    text-align: left !important;
}

.print-report-table tr,.no-padding-table tr{
    text-align: left !important;
}

.print-report-table tr td:nth-child(1) {
    width: 45% !important;
}

.print-report-table tr td:nth-child(2) {
    max-width: 25% !important;
}

.w-99{
    width: 99% !important;
}

.print-checkbox{
    width: 20px !important;
    height: 20px !important;
}
.ss{
    font-weight: 100;
}

.end-report span{
    font-size: 1rem;
}

.barcode-font-size{
    font-size: 1.4rem;
    /* font: 1.156em/1.3 My Gill Sans, Lato, sans-serif; */

}

.barcode-font-size{
    /* width: 370px !important; */
}

.barcode-name{
    font-size: 1.3rem;
    font-weight: 500;
    /* font: 1.156em/1.3 My Gill Sans, Lato, sans-serif; */
}

.barcode-lineheight{
    line-height: 26px;
}

.ck-editor__editable{
    min-height: 400px !important;
    height: 400px !important;
}


.details-heading{
    width: 250px !important;
}

.details-heading2{
    width: 150px !important;
}

.letter-head{
    /* background-image: url('../images/letter_head/logo-o-2.png');   */
    background-size: 270mm auto; 
    background-repeat: no-repeat; 
    background-position: center;
    background-position: center -150px !important;
    
    /* min-height: 15.69in;
    height: 15.69in; */
}

.letter-head .print-font{
    font-size: 20px !important;
}

/* .letter-head .letter-head-footer{
    color: #1f94b9;
    border-bottom: 30px solid green;
    bottom: 0;
    width: 100%;
    font-size: 1.1rem;
    display: flex  !important;
} */
.letter-head-footer{
    display: none;
}

/* .letter-head .letter-head-header{
    display: flex  !important;
} */
.letter-head-header{
    display: none;
}

.letter-head-header img{
    text-align: right !important;
    height: 200px;
    width: auto;
}

.patient-name{
    text-transform: uppercase;
}



.attribute-table , .attribute-table tr , .attribute-table td{
    border: 0 !important;
    margin-bottom: 0rem;
    border-spacing: 0 0px !important;
}

.attribute-table tr td:nth-child(1) {
    width: 25% !important; 
}

.report-ref-range{
    /* font-family: monospace; */
    font-weight: 400;
}



.lab-bill-table td, .lab-bill-table th{
    font-size: 23px !important;
}

.lab-bill{
    font-size: 23px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.lab-bill .name{
    font-size: 27px;
    font-weight: bold;
}

.lab-bill-table , .lab-bill-table td, .lab-bill-table th{
    border: 3px solid black !important;
    padding: 3px !important;
}

.w-95{
    width: 95% !important;
}

.w-75{
    width: 75% !important;
}

.w-25{
    width: 25% !important;
}

.text-dark , .text-dark h2{
    color: black !important;
}

.barcode-width svg,.barcode-width canvas{
    width: 374px !important;
}

.ml-10{
    margin-left: -10px;
}

/****************************************       BILL        ************************************************/

.bill-logo-header{
    display: flex; 
}

.with-out-header .bill-logo-header{
    display: none !important;
}

.with-out-header .bill-heading{
    padding-left: 420px !important;
}

.with-out-header .bill-heading-lab{
    padding-left: 325px !important;
}

/* OTHER */

.report-ref-range p{
    margin-bottom: 0.3rem !important;
}

.report-ref-range tbody tr td{
    background: white !important;
}

.duplicate-watermark{
	-webkit-transform: rotate(331deg);
	-moz-transform: rotate(331deg);
	-o-transform: rotate(331deg);
	transform: rotate(331deg);
	font-size: 72px;
	color: rgba(0, 0, 0, 0.25);
	position: absolute;
	text-transform:uppercase;
	padding-left: 10%;
	padding-top: 30%;
}
