@import 'init.scss';
@import "toastr";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
$primary:#5e72e4;
$success:#2dce89;
$danger:#f5365c;
$light:#ced4da;
$warning:#fb6340;
$info:#11cdef;
$secondary:#f7fafc;
$dark:#212529;


html,body {
    box-sizing: border-box;
    height: 100%;
    font-family: poppins;
    font-size: 17px !important;
    background: #F7F9FF;
    zoom:85%;
}

input,
button{
  font-family: poppins;
}

nav.navbar.bg-dark{
  background: $base-grad !important;
}

nav.navbar.bg-dark .nav-item{
  text-transform: uppercase;
  font-size: 22px !important;
  font-weight:600 !important;
  padding:5px 15px;
}

nav.navbar.bg-light{
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.04) !important;    
  border:1px solid #e6e7ee;
}

.navbar-brand{
  border:none !important;
}

.header-logo{
  height:50px !important;
}

.sub-header .nav-link{
  padding:0;
}

.sub-header{
  // color:#000 !important;
  font-weight:400 !important;
  padding:12px 15px 10px 15px !important;
}

.sub-header a{
  // color:#000 !important;
  font-weight:400 !important;
}

.dropdown-menu, .card, .nav-tabs .nav-link, .rounded {
  border-radius:20px !important;
  padding:10px;
}

.card-header{
  // display:none !important;
}

.btn-primary {
  background: #06c9b9;
  color: #fff !important;
  padding:5px 15px;
}

.btn-success {
  background: #13DB70;
  color: #fff !important;
  padding:5px 15px;
}

.btn-outline-info {
  border: 1px solid #20c997;
  padding:5px 15px;
  background: none;
  color: #20c997;
}

/** ****************************************************************************
Tables and Table Plugins
** ************************************************************************** **/
table{
  border-spacing: 0 10px;
  border-collapse: separate;
  border:none !important;
}

th {
  border-bottom: none;
  padding-bottom: 15px;
  background: #fff !important;
}

tbody tr td {
  background: #F7F9FF !important;
  border: none !important;
  padding:12px !important;
	font-size:15px;
	white-space: unset;
	overflow-x:hidden;
}

tbody tr:hover td {
	white-space:normal;
}

tbody tr td:first-child {
  border-radius:0.7rem 0 0 0.7rem;
  padding-left:25px !important;
}

tbody tr td:last-child {
  border-radius: 0 0.7rem 0.7rem 0;
}

tbody tr:hover td {
  background: #cdf8ed !important;
}

// .fixed-table-toolbar{
//   margin-top:-85px;
// }

.react-bootstrap-table th[data-row-selection]{
  width:60px !important;
}

table input[type='checkbox']{
  height:20px;
  width:20px;
}







.rdrMonth {
    width: 100%;
}


.form-control {
  display: block;
  width: 100%;
  height: unset;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-box .input-group-prepend .input-group-text{
    background-color: white;
    padding-right:0px;
    border-right: unset;

}

.btn-outline-secondary {
    color: #000;
    border-color: #ced4da;
}

.search-box .form-control{
    border-left: unset !important;
}

textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.gbjtWJ {
    display: none !important;
}

.header-logo{
    height: 30px;
}

.menu-list .dropdown-toggle::after , .user-dropdown .dropdown-toggle::after {
    display: none;
}

.menu-list .dropdown-menu{
    min-width: 30rem;
    // box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

.menu-list .dropdown-menu .row{
    margin:unset;
}

.menu-list-items {
    text-align: center;
}

.menu-list-items div{
    color: black;
}

.navbar {
    padding: 0.1rem 1rem;
    
}

.menu-list-items img{
    width: 80px;
}


.menu-list a.dropdown-toggle,.user-dropdown a.dropdown-toggle{
    color: white;
}

.bg-dark{
    background-color: $base-color !important;
}



div.jsx-1795768421 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

p.jsx-1795768421 {
    margin-bottom:0;
}

.user-dropdown a.dropdown-toggle {
    padding-right: 0;
    padding-left: 2px;
}



// .table {
//     background-color: white;
// }

.table-lg td, .table-lg th {
    padding: 1rem;
}

.table-sm td, .table-sm th {
    padding: .6rem;
}


.noscroll { 
    overflow: hidden;
  }
  
  .overlay { 
     position: fixed; 
     overflow-y: scroll;
     top: 0; right: 0; bottom: 0; left: 0; }
  
  // [aria-hidden="true"]  { display: none; }
  [aria-hidden="false"] { display: block; }

  .container-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    animation-delay: 1s;
  }
  
  .item-1 {
    width: 20px;
    height: 20px;
    background: #f583a1;
    border-radius: 50%;
    background-color: #eed968;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50%,
      75% {
      transform: scale(2.5);
    }
    78%, 100% {
      opacity: 0;
    }
  }
  .item-1:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eed968;
    opacity: 0.7;
    animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
    animation-delay: 200ms;
    transition: 0.5s all ease;
    transform: scale(1);
  }
  
  .item-2 {
    width: 20px;
    height: 20px;
    background: #f583a1;
    border-radius: 50%;
    background-color: #eece68;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50%,
      75% {
      transform: scale(2.5);
    }
    78%, 100% {
      opacity: 0;
    }
  }
  .item-2:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eece68;
    opacity: 0.7;
    animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
    animation-delay: 400ms;
    transition: 0.5s all ease;
    transform: scale(1);
  }
  
  .item-3 {
    width: 20px;
    height: 20px;
    background: #f583a1;
    border-radius: 50%;
    background-color: #eec368;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50%,
      75% {
      transform: scale(2.5);
    }
    78%, 100% {
      opacity: 0;
    }
  }
  .item-3:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eec368;
    opacity: 0.7;
    animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
    animation-delay: 600ms;
    transition: 0.5s all ease;
    transform: scale(1);
  }
  
  .item-4 {
    width: 20px;
    height: 20px;
    background: #f583a1;
    border-radius: 50%;
    background-color: #eead68;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50%,
      75% {
      transform: scale(2.5);
    }
    78%, 100% {
      opacity: 0;
    }
  }
  .item-4:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eead68;
    opacity: 0.7;
    animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
    animation-delay: 800ms;
    transition: 0.5s all ease;
    transform: scale(1);
  }
  
  .item-5 {
    width: 20px;
    height: 20px;
    background: #f583a1;
    border-radius: 50%;
    background-color: #ee8c68;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50%,
      75% {
      transform: scale(2.5);
    }
    78%, 100% {
      opacity: 0;
    }
  }
  .item-5:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ee8c68;
    opacity: 0.7;
    animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
    animation-delay: 1000ms;
    transition: 0.5s all ease;
    transform: scale(1);
  }

  .nav-tabs {
     border-bottom: unset;
}

.rbc-month-row {
  overflow: unset;
  min-height: 125px;
}

.invalid-feedback-show {
  display: 'block';
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.border-1 {
  border-width: 1px!important;
}

.border-2 {
  border-width: 2px!important;
}

.border-3 {
  border-width: 3px!important;
}

.border-4 {
  border-width: 4px!important;
}

.border-5 {
  border-width: 5px!important;
}


@mixin attr-x($attr, $attr-count: 5, $unit: 'px') {
  $attr-list: null;
  @for $i from 1 through $attr-count {
      $attr-value: 1 * $i;

      .#{$attr}-#{$attr-value}-primary {
          #{$attr}: #{$attr-value}#{$unit} solid #{$primary} !important;
      }
      .#{$attr}-#{$attr-value}-success {
          #{$attr}: #{$attr-value}#{$unit} solid #{$success} !important;
      }
      .#{$attr}-#{$attr-value}-danger {
          #{$attr}: #{$attr-value}#{$unit} solid #{$danger} !important;
      }
      .#{$attr}-#{$attr-value}-warning {
          #{$attr}: #{$attr-value}#{$unit} solid #{$warning} !important;
      }
      .#{$attr}-#{$attr-value}-info {
          #{$attr}: #{$attr-value}#{$unit} solid #{$info} !important;
      }
      .#{$attr}-#{$attr-value}-secondary {
          #{$attr}: #{$attr-value}#{$unit} solid #{$secondary} !important;
      }
      .#{$attr}-#{$attr-value}-dark {
          #{$attr}: #{$attr-value}#{$unit} solid #{$dark} !important;
      }
      .#{$attr}-#{$attr-value}-light {
          #{$attr}: #{$attr-value}#{$unit} solid #{$light} !important;
      }

      $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
  }

  #{$attr-list} {
      //append style to all classes
  }
}

@include attr-x('border-right', 5, 'px');
@include attr-x('border-top', 5, 'px');
@include attr-x('border-bottom', 5, 'px');
@include attr-x('border-left', 5, 'px');

.appointment-timeslot-table{
  display: block;
  height: 450px;
  overflow-y: scroll;
}

.modal-backdrop.show{
  // opacity: 0 !important;
}

.modal-backdrop {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: unset !important;
  height: unset !important;

}

@media (min-width: 1200px){
  .modal-xl {
    max-width: 1140px !important;
}
}

@media (min-width: 992px){
  .modal-lg, .modal-xl {
     max-width:97% !important;
  }
}


.react-bootstrap-table table {
  table-layout: unset !important;
}

.pac-container {  
  z-index: 9999999 !important;  
  // top: 61% !important;   //your input height
  // left: 47% !important;
  position: fixed;
  display: inline-block;
  float: left;
}

ul.pagination {
  justify-content: center;
  position: absolute;
  margin-top: -42px;
  right: 0;
  padding-right: 55px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 35px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: 25px;
  border-radius: 50%;
}

ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: #06a1d5;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}